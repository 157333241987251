import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
import useSettings from "src/hooks/useSettings";
import Page from "src/components/Page";
import {
  AnalyticsCurrentVisits,
  AnalyticsWebsiteVisits,
  AnalyticsWidgetSummary,
  AnalyticsCurrentSubject,
  AnalyticsConversionRates,
} from "src/sections/@dashboard/general/analytics";
import { useEffect, useState } from "react";
import axios from "src/utils/axios";
import Loader from "src/pages/dashboard/Loader";

export default function PlayerStats({ profile }) {
  const theme = useTheme();
  const [rankedSolo, setRankedSolo] = useState(null);
  const [matchList, setMatchList] = useState([]);
  const [averages, setAverages] = useState(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${profile.userId}/players/${profile._id}/games/League%20of%20Legends`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setRankedSolo(
            res.data.data.entries.find(
              (entry) => entry.queueType === "RANKED_SOLO_5x5"
            )
          );
          setMatchList(res.data.data.matchList);
          setAverages(res.data.data.averages);
          setMounted(true);
        }
      })
      .catch((error) => {
        window.location.href = `${window.location.pathname}${
          typeof error === "string" ? `?error=${encodeURIComponent(error)}` : ""
        }`;
      });
  }, [profile.userId, profile._id]);

  const { themeStretch } = useSettings();

  const reduce = (matchList, field) => {
    const result = [];
    matchList = matchList.map((match) => Object({ label: match.stats.champion, value: match.stats[field] }));

    [...new Set(matchList.map(item => item.label))].forEach(label => {
        const data = matchList.filter(item => item.label === label);
        const value = +Number(data.reduce((acc, item) => acc + item.value, 0) / data.length).toFixed();
        result.push({ label, value });
    });

    return result;
  }

  return (
    <>
      {!mounted ? (
        <Loader loading={!mounted} />
      ) : (
        <Page title="Player Stats: League of Legendss">
          <Container maxWidth={themeStretch ? false : "xl"}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Player Stats: League of Legends
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Ranked Solo"
                  img={'/assets/icons/tools/tool1.png'}
                  value={
                    rankedSolo
                      ? `${rankedSolo.tier} ${rankedSolo.rank}`
                      : "Unranked"
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="KDA"
                  color="info"
                  img={'/assets/icons/tools/tool2.png'}
                  value={averages ? averages.kda : 0}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Avg K / D / A"
                  color="error"
                  img={'/assets/icons/tools/tool3.png'}
                  value={`${averages.kills} / ${averages.deaths} / ${averages.assists}`}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Avg CS"
                  color="warning"
                  img={'/assets/icons/tools/tool4.png'}
                  value={averages ? averages.cs : 0}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={8}>
                <AnalyticsWebsiteVisits
                  title="Match History"
                  subheader="Stats of recent matches"
                  chartLabels={matchList.map((match) => {
                    const date = new Date(match.info.gameCreation);
                    const [month, day, year] = [
                      date.getMonth(),
                      date.getDate(),
                      date.getFullYear(),
                    ];

                    return `${month + 1}/${day}/${year}`;
                  })}
                  chartData={[
                    {
                      name: "Kills",
                      type: "line",
                      fill: "solid",
                      data: matchList.map((match) => match.stats.kills),
                    },
                    {
                      name: "Deaths",
                      type: "line",
                      fill: "solid",
                      data: matchList.map((match) => match.stats.deaths),
                    },
                    {
                      name: "Assists",
                      type: "line",
                      fill: "solid",
                      data: matchList.map((match) => match.stats.assists),
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <AnalyticsCurrentVisits
                  title={`Win Rate: ${
                    (matchList.filter((match) => match.stats.win).length /
                      matchList.length) *
                    100
                  }%`}
                  chartData={[
                    {
                      label: "Wins",
                      value: matchList.filter((match) => match.stats.win)
                        .length,
                    },
                    {
                      label: "Losses",
                      value: matchList.filter((match) => !match.stats.win)
                        .length,
                    },
                  ]}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.error.main,
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={8}>
                <AnalyticsConversionRates
                  title="Champion Stats"
                  subheader="Levels of recent champions, averaged across last 20 matches"
                  chartData={reduce(matchList, 'championLevel')}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <AnalyticsCurrentSubject
                  title={`General Stats`}
                  chartLabels={[...new Set(matchList.map((match) => match.stats.champion))]}
                  // eslint-disable-next-line no-new-object
                  chartData={[
                    {
                      name: "Damage Taken",
                      data: reduce(matchList, 'damageTaken').map(item => item.value),
                    },
                    {
                      name: "Gold",
                      data: reduce(matchList, 'gold').map(item => item.value),
                    },
                    {
                      name: "Damage Dealt",
                      data: reduce(matchList, 'damageDealt').map(item => item.value),
                    },
                  ]}
                  chartColors={[...Array(6)].map(
                    () => theme.palette.text.secondary
                  )}
                />
              </Grid>
            </Grid>
          </Container>
        </Page>
      )}
    </>
  );
}
